import { get } from 'vuex-pathify';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateForAPI } from '~/util/apiDateFormat';
import { isFlamingoInstance } from '~/util/utility-functions';
import appConsoleHttpAPI from '../../api/app-console-http';

const computed = {
  dates: get('dashboard/dates'),
};
const methods = {
  async fetchMediaTypes() {
    try {
      const newData = [];
      // let obj;
      // const data = await advertiserReportsAPI.mediaTypes(
      const data = await appConsoleHttpAPI.fetchMediaTypeOptions(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString({
          advertiser: this.advertiser ? this.advertiser.name : '',
          client: this.account ? this.account.name : '',
          endDate: formatDateForAPI(this.dates.endDate),
          startDate: formatDateForAPI(this.dates.startDate),
          includeNonFlip: isFlamingoInstance(),
        })
      );

      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          newData.push({ value: data[i], checked: isFlamingoInstance() });
        }
        this.$store.set('dashboard/filters@mediaTypeOptions', newData);
        return;
      }

      this.$store.set('dashboard/filters@mediaTypeOptions', []);
    } catch (err) {
      this.$store.set('dashboard/filters@mediaTypeOptions', []);
      console.error('error fetching mediaTypes ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    } finally {
      this.$store.set('dashboard/filters@selectedMediaTypeIndex', 0);
    }
  },
  async fetchSpecificMediaTypeOptions(intialIoOptions, initialAdGroupOption) {
    const newData = [];

    try {
      // let obj;
      const payload = {
        advertiser: this.advertiser ? this.advertiser.name : '',
        client: this.account ? this.account.name : '',
        includeNonFlip: isFlamingoInstance(),
      };
      const ioOptions = intialIoOptions || this.filters.ioOptions || [];
      const adGroupOptions = initialAdGroupOption || this.filters.adGroupOptions || [];
      const selectedIoOptions = ioOptions.filter((opt) => opt.checked);
      const selectedAdGroupOptions = adGroupOptions.filter((opt) => opt.checked);
      if (selectedIoOptions.length > 0 && selectedIoOptions.length !== ioOptions.length) {
        const ioIdsString = [
          ...new Set(
            selectedIoOptions.map((ioOption) => ioOption?.key || '').filter((opt) => opt !== '')
          ),
        ].join(',');
        if (ioIdsString) payload.ioIds = ioIdsString;
      }
      if (
        selectedAdGroupOptions.length > 0 &&
        selectedAdGroupOptions.length !== adGroupOptions.length
      ) {
        const dataArray = [];
        selectedAdGroupOptions.forEach((adGroup) => {
          const { liData = [] } = adGroup;
          liData.map((data) => dataArray.push(`${data.adreadyId}|${data.flightStartDate}`));
        });
        if (dataArray.length > 0) {
          payload.adGroups = dataArray.join(',');
        }
      }
      const data = await advertiserReportsAPI.mediaTypes(
        this.advertiser ? this.advertiser.id : 0,
        buildQueryString(payload)
      );

      if (data.length !== 0) {
        for (let i = 0; i < data.length; i++) {
          newData.push({ value: data[i], checked: true });
        }
      }
    } catch (err) {
      console.error('error fetching mediaTypes ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
    return newData;
  },
};

export default { computed, methods };
